/* uSonar 共通
*-------------------------------------------------------------------------*/

/**
* ログイン画面の背景設定
*/
@mixin login-background() {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: map-get($app-min-size, 'width');
  min-height: map-get($app-min-size, 'height');
  background-image: url('/assets/images/top.jpg');
  background-size: cover;
  background-position: center center;
}

/**
* サイドボタン
* $height: 全体の高さ(表示するボタンの数に応じて変わる)
*   表示するボタンが1〜2つ: 134px
*   表示するボタンが3つ: 200px
* $left: 左側にボタンを配置する場合 60px
* $right: 右側にボタンを配置する場合 0px
*/
@mixin side-button($height: auto, $left: null, $right: null) {
  cursor: pointer;
  position: fixed;
  top: 300px;
  z-index: 999;

  height: $height;
  left: $left;
  right: $right;
}

/**
* アクションボタン
* 配置を変えたい場合は引数に良い感じの値を設定
*/
@mixin action-button($right: -15px, $bottom: -15px) {
  position: fixed;
  right: $right;
  bottom: $bottom;
}

/**
* テーブルレイアウト　共通
* 固定セルや可変するセルの設定
*-------------------------------------------------------------------------*/

// テーブルとページネータを包括した領域
@mixin table-wrapper() {
  box-shadow: $content-box-shadow;
  background: $usonar-main-color;
  min-height: 0px;
  height: 100%;
}

// テーブルのスクロール領域
@mixin table-container() {
  // テーブルコンテナの高さからテーブルヘッダの高さを引く
  height: calc(100% - 56px);
  box-sizing: content-box;
}

// テーブルの領域
@mixin table() {
  width: 100%;
  height: 100%;
}

//「もっと頂点」のカラム設定
@mixin column-more-vert($width: 60px) {
  width: $width;
  min-width: 40px;
  padding-right: $content-margin;
}

// 年月日のカラム設定
@mixin column-date() {
  max-width: 0px;
  min-width: 140px;
  padding-right: $content-margin;
}

// 良い感じに幅を指定したい固定カラムの設定
@mixin column-fixed-custom($width: 100px) {
  max-width: $width;
  min-width: $width;
  width: $width;
  padding-right: $content-margin;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 良い感じに幅を指定したい可変カラムの設定
@mixin column-variable-custom($width: 10%) {
  max-width: 0px;
  width: $width;
  padding-right: $content-margin;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// カラムの幅を画面サイズに合わせて可変させる設定(大)
@mixin column-large() {
  max-width: 0px;
  width: 30%;
  padding-right: $content-margin;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// カラムの幅を画面サイズに合わせて可変させる設定(中)
@mixin column-medium() {
  max-width: 0px;
  width: 20%;
  padding-right: $content-margin;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// カラムの幅を画面サイズに合わせて可変させる設定(中小)
@mixin column-medium-small() {
  max-width: 0px;
  width: 15%;
  padding-right: $content-margin;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// カラムの幅を画面サイズに合わせて可変させる設定(小)
@mixin column-small() {
  max-width: 0px;
  width: 10%;
  padding-right: $content-margin;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* ホーム画面　共通
*-------------------------------------------------------------------------*/

/* ホーム画面 ページ領域
* $is-padding: 要素の領域に余白を設ける場合は true
* 💣perfect-scrollbarに適用する場合、paddingを設定するとズレる💣
*/
@mixin home-content-wrapper($is-padding: false) {
  width: calc(100vw - #{map-get($global-sidenav-button, 'icon-width')});

  min-width: calc(#{map-get($app-min-size, 'width')} - #{map-get($global-sidenav-button, 'icon-width')});

  height: calc(100vh - #{map-get($global-header, 'height')});

  min-height: calc(#{map-get($app-min-size, 'height')} - #{map-get($global-header, 'height')});

  padding: if($is-padding, 24px, 0px);
}

/* ターゲット検索　共通
*-------------------------------------------------------------------------*/

/* ターゲット検索 ページ領域
* $is-padding: 要素の領域に余白を設ける場合は true
* 💣perfect-scrollbarに適用する場合、paddingを設定するとズレる💣
*/
@mixin target-search-content-wrapper($is-padding: false) {
  // ターゲット検索 のヘッダ領域
  $target-search-header: 60px;

  width: calc(100vw - #{map-get($global-sidenav-button, 'icon-width')});

  min-width: calc(#{map-get($app-min-size, 'width')} - #{map-get($global-sidenav-button, 'icon-width')});

  height: calc(100vh - #{map-get($global-header, 'height')} - #{$target-search-header});

  padding: if($is-padding, 24px, 0px);
}

/**
 * ターゲット検索 ページ領域
 * コンテンツのスクロールに対応するための領域設定
 */
@mixin target-search-content-body-wrapper() {
  $target-search-header: 60px;

  padding: 24px;

  min-width: calc(#{map-get($app-min-size, 'width')} - #{map-get($global-sidenav-button, 'icon-width')});

  min-height: calc(
    #{map-get($app-min-size, 'height')} - #{map-get($global-header, 'height')} - #{$target-search-header}
  );

  height: calc(100vh - #{map-get($global-header, 'height')} - #{$target-search-header});
}

/* ベン図
*-------------------------------------------------------------------------*/

// 条件指定セレクタ
@mixin selector-color($background-color, $border-color) {
  background-color: $background-color;
  border: $border-color 1.5px solid;
  color: $dark-gray;
}

/* クライアント管理　共通
*-------------------------------------------------------------------------*/

/* クライアント管理 ページ領域
* $is-padding: 要素の領域に余白を設ける場合は true
* 💣perfect-scrollbarに適用する場合、paddingを設定するとズレる💣
*/
@mixin client-manage-content-wrapper($is-padding: false) {
  // パンくずの領域
  $client-manage-header: 34px;

  width: 100%;

  min-width: calc(#{map-get($app-min-size, 'width')} - #{map-get($global-sidenav-button, 'icon-width')});

  height: calc(100vh - #{map-get($global-header, 'height')} - #{$client-manage-header});

  min-height: calc(
    #{map-get($app-min-size, 'height')} - #{map-get($global-header, 'height')} - #{$client-manage-header}
  );

  padding: if($is-padding, 24px, 0px);
}

/*
* ページ内に描画時の画面サイズに応じて可変となるコンテンツの高さを取得する。
* 引数の $height にはページ内にある固定の高さの合計を与える。
*/
@mixin page-content-height($height: 0) {
  // パンくずの領域
  $client-manage-header: 34px;

  width: 100%;

  height: calc(100vh - #{map-get($global-header, 'height')} - #{$client-manage-header} - $height);
}

/*
 * PerfectScrollbarのどうしても使えない部分に対して，それっぽいスクロールバーを設定するCSS
 */
@mixin scrollbar-design() {
  /* Win × Firefox用のスクロールバー用 */
  scrollbar-color: #aaa #fff;
  /* Chrome用のスクロールバー用 */
  ::-webkit-scrollbar {
    width: 7px;
    height: 15px;
    background-color: #fff;
    z-index: 1;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aaa;
  }
}
