@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;
@include mat.core();

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette(mat.$m2-blue-palette, 600),
      accent: mat.m2-define-palette(mat.$m2-pink-palette, 500),
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

$my-check-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette(mat.$m2-blue-palette, 600),
      accent: mat.m2-define-palette(mat.$m2-blue-palette, 600),
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);
@include mat.checkbox-theme($my-check-theme);
@include mat.radio-theme($my-check-theme);

$my-form-field-theme: mat.m2-define-light-theme(
  (
    density: -1,
  )
);
@include mat.form-field-theme($my-form-field-theme);

@import '_import';
@import '_reset';

/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100vh;
  font-size: 62.5%;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
}

body {
  background-color: $usonar-base-color;
  font-size: 1.4rem;
  color: $gray12;
  height: 100%;
  line-height: 1.4;
}

.unstyled-button {
  display: inline-block;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: fit-content;
  height: fit-content;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}

.mat-simple-snackbar {
  white-space: pre-wrap;
}

/** SnackBarのメッセージカラー */
.error {
  --mdc-snackbar-container-color: #ffcdd2 !important;
  --mdc-snackbar-supporting-text-color: #263238 !important;
}

.warning {
  --mdc-snackbar-container-color: #fff9c4 !important;
  --mdc-snackbar-supporting-text-color: #263238 !important;
}

.complete {
  --mdc-snackbar-container-color: #c8e6c9 !important;
  --mdc-snackbar-supporting-text-color: #263238 !important;
}

.confirm-dialog .mat-dialog-container {
  padding: 0;
}

.mat-mdc-button-persistent-ripple::before {
  opacity: 0 !important;
}

//　名刺詳細の名刺メモ上書き
.visit-card-memo-textarea .mat-mdc-form-field {
  height: 100%;

  .mat-mdc-text-field-wrapper {
    height: 100%;
    .mat-mdc-form-field-flex {
      height: 100%;

      div {
        height: 100%;

        textarea {
          height: 100%;
          resize: none;
        }
      }
    }
  }
}
