/* AngularMaterialのcss設定を上書きする
*-------------------------------------------------------------------------*/

/** TODO:フォーカスリングいい感じに当てたいマン（@smiyahara） */
// https://github.landscape.co.jp/uSonarReplace/usonar-front/issues/2596
/** フォーカスリングを当てる */
//// ラベルを含むボタン
//.mat-button,
//.mat-stroked-button,
//.mat-raised-button,
//.mat-icon-button {
//  &:focus::after {
//    content: '';
//    position: absolute;
//    top: -3px;
//    right: -3px;
//    bottom: -3px;
//    left: -3px;
//    border-radius: 5px;
//    box-shadow: 0 0 0 3px $focus-ring-primary;
//  }
//  &.mat-delete {
//    &:focus::after {
//      box-shadow: 0 0 0 3px $focus-ring-delete;
//    }
//  }
//}
//// アイコンボタン
//.mat-icon-button {
//  &:focus::after {
//    border-radius: 100vmax;
//  }
//}

/* Angular Material Button Style */

// 背景が透過されているボタン（登録、検索などで使うやつ）
.mat-mdc-button.mat-primary {
  color: $usonar-accent-color;
}

// 背景が透過されているボタン（削除実行などで使う）
.mat-mdc-button.mat-delete {
  color: $usonar-delete-color;
  &:hover {
    color: white;
    background-color: $usonar-delete-color;
  }
}

.mat-mdc-unelevated-button.mat-delete {
  background-color: $usonar-delete-color !important;
  color: #fff !important;
  &:hover {
    filter: saturate(0.8);
  }
}

// カードなどのコンテンツに配置するフラットなボタン(登録、検索などで使うやつ)
.mat-mdc-outlined-button.mat-primary {
  background: $usonar-accent-color;
  color: $usonar-main-color !important;
  min-width: 120px;
  min-height: 40px;
  height: 40px;
  &:hover {
    filter: saturate(0.8);
  }
}

// カードなどのコンテンツに配置するフラットなボタン(削除実行などで使う)
.mat-mdc-outlined-button.mat-delete {
  background: $usonar-delete-color;
  color: $usonar-main-color !important;
  min-width: 120px;
  min-height: 40px;
  height: 40px;
  &:hover {
    filter: saturate(0.8);
  }
}

// カードなどのコンテンツに配置するフラットなボタン(キャンセルとかで使うやつ)
.mat-mdc-outlined-button.mat-white,
.mat-stroked-button.mat-white {
  background: $usonar-main-color;
  min-width: 120px;
  min-height: 40px;
  height: 40px;
  &:hover {
    filter: saturate(0.8);
  }
}

.mat-mdc-button[disabled],
.mat-mdc-raised-button[disabled],
.mat-mdc-outlined-button[disabled] {
  background: none !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

// ページヘッダ、ページのフッター部分に配置する凹凸のあるボタン(一覧から登録画面へ遷移するボタンで使うやつ)
.mat-mdc-raised-button.mat-primary {
  background: $usonar-accent-color;
  min-width: 120px;
  min-height: 40px;
  height: 40px;
  border-style: solid !important;
  border-color: $usonar-accent-color !important;
  border-width: 1px !important;
  &:hover {
    filter: saturate(0.8);
  }
}

// ページヘッダ、ページのフッター部分に配置する凹凸のあるボタン(キャンセルとかで使うやつ)
.mat-mdc-raised-button.mat-white {
  background: $usonar-main-color;
  min-width: 120px;
  min-height: 40px;
  height: 40px;
  &:hover {
    filter: saturate(0.8);
  }
}

// ページヘッダ、ページのフッター部分に配置する凹凸のあるボタン(削除実行などで使う)
.mat-mdc-raised-button.mat-delete {
  background: $usonar-delete-color;
  color: $usonar-main-color;
  min-width: 120px;
  min-height: 40px;
  height: 40px;
  border-style: solid !important;
  border-color: $usonar-delete-color !important;
  border-width: 1px !important;
  &:hover {
    filter: saturate(0.8);
  }
}

.mat-mdc-raised-button[disabled] {
  background: none !important;
  color: rgba(0, 0, 0, 0.26) !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.26) !important;
  border-width: 1px !important;
}

/** Angular Material SnackBar Style */
.snackbar-config {
  color: white;
  background-color: $usonar-accent-color !important;
}

/** Angular Material MatFormField Style */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-subscript-wrapper {
  overflow: visible !important;
}

/** Angular Material Tab Group Style */
.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $usonar-accent-color !important;
}

/** Angular StarRating Style */
.rating {
  outline: none;
}

/** Angular ButtonToggle Style */
mat-button-toggle-group {
  height: 40px;
  .mat-mdc-button-toggle-label-content {
    line-height: 40px !important;
  }
}

.mat-mdc-button-toggle-checked {
  color: white !important;
  background: $usonar-accent-color !important;
}

.mat-mdc-button-toggle-checked .mat-mdc-button-toggle-focus-overlay {
  border-bottom: none !important;
}

/** Angular FormFiled */
.custom-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}

.small-form-field {
  .mat-mdc-form-field-flex {
    width: 240px;
  }
  .mat-mdc-form-field-flex {
    height: 44px;
    align-items: center;
  }
  .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
    height: fit-content;
    margin-top: 0;
    padding: 12px 0;
    border: none;
  }
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
  .mat-mdc-form-field-label {
    font-size: 1.2rem;
    padding-top: 12px;
  }

  input {
    margin-top: 6px;
  }
}

/** Angular Material Chip */
.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: $usonar-accent-color !important;
  color: $usonar-main-color !important;
}

.mdc-evolution-chip__text-label {
  color: $usonar-main-color !important;
}

/** Material Stepper */
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-edit {
  background-color: $usonar-accent-color;
}

.mat-mdc-header-cell {
  z-index: 5 !important;
}

// フォーカスすると謎のアウトラインが表示されるので、消す
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-selection-list {
  outline: none;
}

// MatToggleButtonのラベル調整
// マテリアルのボタンの高さをグルーバルに設定しているので「line-height」を指定しないとラベルがずれる。
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 40px;
}
